<template>
    <div v-show="isOpend" class="k-video">
        <div @click="onMaskClick" class="k-video-mask"></div>

        <div
            :class="[
                'k-video-player',
                isLandscape && 'k-video-player--landscape'
            ]"
        >
            <video
                v-if="info.id"
                :src="require(`@/assets/videos/${info.type}/${info.id}.mp4`)"
                ref="video"
                @canplay="onCanPlay"
                @timeupdate="onTimeUpdate"
                playsinline=""
                webkit-playsinline="true"
                x5-playsinline="true"
                preload="auto"
                controls
                x-webkit-airplay="allow"
                poster
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },

        value: {
            type: Boolean
        }
    },

    data() {
        return {
            isOpend: false,
            isLandscape: false,
            isUC: this.$userAgent.isUC,
            isIPhone: this.$userAgent.isIPhone
        };
    },

    methods: {
        onMaskClick() {
            this.$emit("input", false);
        },

        onCanPlay() {},

        onTimeUpdate(e) {
            const { videoWidth, videoHeight } = e.target;
            const isLandscape = videoWidth > videoHeight;

            if (this.isLandscape === isLandscape) return;

            this.isLandscape = isLandscape;
        },

        play() {
            this.$refs.video.play();
        }
    },

    watch: {
        value(newVal) {
            this.isOpend = newVal;

            if (!newVal) {
                this.$refs.video.pause();
                return;
            }

            setTimeout(() => {
                this.$refs.video.play();

                if (this.isUC && this.isIPhone) {
                    this.$emit("input", false);
                }
            }, 0);
        },

        info: {
            handler() {
                this.$refs.video && this.$refs.video.load();
            },
            // 深度监听
            deep: true
        }
    },

    mounted() {}
};
</script>

<style lang="less" scoped>
.k-video {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;

    &-mask {
        width: 100%;
        height: 100%;
        background: #000;
    }

    &-player {
        width: calc(90vh / 986 * 520);
        height: 90vh;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: calc(-90vh / 986 * 520 / 2);
        margin-top: calc(-90vh / 2);

        &--landscape {
            width: 100vw;
            height: calc(100vw / 800 * 600);
            margin-left: calc(-50vw);
            margin-top: calc(-50vw / 800 * 600);
        }

        video {
            width: 100%;
            height: 100%;
            background: #000;
            position: relative;
        }
    }
}

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    .k-video {
        &-player {
            width: calc(70vh / 986 * 520);
            height: 70vh;
            margin-left: calc(-70vh / 986 * 520 / 2);
            margin-top: calc(-70vh / 2);
        }
    }
}
// xr
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
    .k-video {
        &-player {
            width: calc(70vh / 986 * 520);
            height: 70vh;
            margin-left: calc(-70vh / 986 * 520 / 2);
            margin-top: calc(-70vh / 2);
        }
    }
}

// xs max
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
    .k-video {
        &-player {
            width: calc(70vh / 986 * 520);
            height: 70vh;
            margin-left: calc(-70vh / 986 * 520 / 2);
            margin-top: calc(-70vh / 2);
        }
    }
}
</style>
