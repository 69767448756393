export default [
    {
        id: 1,
        type: "chinese",
        imgType: 'yu',
        list: [
            {
                name: "自信",
                id: 1
            },
            {
                name: "森林",
                id: 2
            },
            {
                name: "童话",
                id: 3
            },
            {
                name: "伙伴",
                id: 4
            },
            {
                name: "感恩",
                id: 5
            },
            {
                name: "团结",
                id: 6
            }
        ]
    },
    {
        id: 2,
        type: "maths",
        imgType: 'shu',
        list: [
            {
                name: "还原问题",
                id: 1
            },
            {
                name: "找规律",
                id: 2
            },
            {
                name: "植树问题",
                id: 3
            },
            {
                name: "年龄问题",
                id: 4
            },
            {
                name: "盈亏问题",
                id: 5
            },
            {
                name: "方阵问题",
                id: 6
            }
        ]
    },
    {
        id: 3,
        type: "english",
        imgType: 'ying',
        list: [
            {
                name: "doll",
                id: 1
            },
            {
                name: "ball",
                id: 2
            },
            {
                name: "balloon",
                id: 3
            },
            {
                name: "kite",
                id: 4
            },
            {
                name: "robot",
                id: 5
            },
            {
                name: "drum",
                id: 6
            }
        ]
    }
];
