<template>
    <div class="g-page">
        <Nav />

        <picture>
            <source
                media="(max-width: 1024px)"
                srcset="@/assets/images/mobile/container/videos/banner.png"
            />
            <source
                media="(min-width: 1025px)"
                srcset="@/assets/images/container/videos/banner.png"
            />
            <img
                class="banner"
                src="@/assets/images/container/videos/banner.png"
            />
        </picture>

        <Videos />

        <Footer />

        <BackToTop />
    </div>
</template>

<script>
import Nav from "@/components/nav.vue";
import Videos from "@/components/videos.vue";
import Footer from "@/components/footer.vue";
import BackToTop from "@/components/back-to-top.vue";

export default {
    components: {
        Nav,
        Videos,
        Footer,
        BackToTop
    },

    data() {
        return {};
    }
};
</script>

<style lang="less" scoped>
.banner {
    width: 100%;
}

@media (max-width: 1024px) {
    .banner {
    }
}
</style>
