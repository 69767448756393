<template>
    <div v-if="isOpend" class="k-video">
        <div @click="onMaskClick" class="k-video-mask"></div>

        <div
            :class="[
                'k-video-player',
                isLandscape && 'k-video-player--landscape'
            ]"
        >
            <video-player
                v-if="info.id"
                :src="require(`@/assets/videos/${info.type}/${info.id}.mp4`)"
                ref="video"
                @canplay="onCanPlay($event)"
                :options="playerOptions"
                :playsinline="true"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },

        value: {
            type: Boolean
        }
    },

    data() {
        return {
            isOpend: false,
            isLandscape: false,
            playerOptions: {
                // videojs options
                autoplay: true
            }
        };
    },

    methods: {
        onMaskClick() {
            this.$emit("input", false);
        },

        onCanPlay(e) {
            this.isLandscape = e.videoWidth() > e.videoHeight();
        }
    },

    watch: {
        value(newVal) {
            this.isOpend = newVal;

            if (!newVal) {
                return;
            }

            this.$set(this.playerOptions, "sources", [
                {
                    type: "video/mp4",
                    src: require(`@/assets/videos/${this.info.type}/${this.info.id}.mp4`)
                }
            ]);

            setTimeout(() => {
                // this.$refs.video.play();
            }, 0);
        }
    }
};
</script>

<style lang="less" scoped>
.k-video {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999999;

    &-mask {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
    }

    &-player {
        width: calc(100vw / 1920 * 520);
        height: calc(100vw / 1920 * 920);
        position: absolute;
        left: 50%;
        top: 50%;
        padding: calc(100vw / 1920 * 30) calc(100vw / 1920 * 20);
        background: #fff;
        border-radius:30px;
        margin-left: calc(-100vw / 1920 * 260);
        margin-top: calc(-100vw / 1920 * 460);

        &--landscape {
            width: calc(100vw / 1920 * 1024);
            height: calc(100vw / 1920 * 576);
            padding: calc(100vw / 1920 * 25) calc(100vw / 1920 * 60)
                calc(100vw / 1920 * 40);
            margin-left: calc(-100vw / 1920 * 512);
            margin-top: calc(-100vw / 1920 * 288);
        }
    }
}
</style>
