<template>
    <div class="videos">
        <div class="videos-item" v-for="(item, idx) in videos" :key="idx">
            <div class="videos-item__outer">
                <div class="videos-item__title">
                    <!-- <img
                        :src="
                            require(`@/assets/images/container/videos/title_${item.id}.png`)
                        "
                    /> -->

                    <picture>
                        <source
                            media="(max-width: 1024px)"
                            :srcset="
                                require(`@/assets/images/container/videos/title_${item.id}.png`)
                            "
                        />
                        <source
                            media="(min-width: 1025px)"
                            :srcset="
                                require(`@/assets/images/container/videos/title_${item.id}.png`)
                            "
                        />
                        <img
                            :src="
                                require(`@/assets/images/container/videos/title_${item.id}.png`)
                            "
                        />
                    </picture>
                </div>
                <div class="videos-item-list">
                    <div
                        class="videos-item-list__item"
                        v-for="(video, idx) in item.list"
                        :key="idx"
                    >
                        <div
                            @click="onVideoClick(item.type, video)"
                            class="videos-item-list__video"
                        >
                            <!-- <video
                                :src="
                                    require(`@/assets/videos/${item.type}/${video.id}.mp4`)
                                "
                            /> -->

                            <!-- <img
                                class="videos-item-list__cover"
                                :src="
                                    require(`@/assets/images/container/videos/covers/${item.imgType}_${video.id}.png`)
                                "
                            /> -->

                            <picture>
                                <source
                                    media="(max-width: 1024px)"
                                    :srcset="
                                        require(`@/assets/images/mobile/container/videos/covers/${item.imgType}_${video.id}.png`)
                                    "
                                />
                                <source
                                    media="(min-width: 1025px)"
                                    :srcset="
                                        require(`@/assets/images/container/videos/covers/${item.imgType}_${video.id}.png`)
                                    "
                                />
                                <img
                                    class="videos-item-list__cover"
                                    :src="
                                        require(`@/assets/images/container/videos/covers/${item.imgType}_${video.id}.png`)
                                    "
                                />
                            </picture>

                            <div class="videos-item-list__icon">
                                <img
                                    :src="
                                        require(`@/assets/images/container/videos/btn_play.png`)
                                    "
                                />
                            </div>
                        </div>

                        <div class="videos-item-list__name">
                            {{ video.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="downloads">
            <div class="downloads-subtitle">
                下载兜兜龙查看更多同步视频
            </div>

            <div class="downloads-title">兜兜龙</div>

            <div class="downloads-msg">小学生同步自学好助手</div>

            <DownloadWays v-if="!isMobile" />
            <MobileDownloadWays v-else class="mobile-download-ways" />
        </div>

        <Video v-if="!isMobile" v-model="isVideoOpened" :info="curVideo" />
        <MobileVideo
            ref="mobileVideo"
            v-else
            v-model="isVideoOpened"
            :info="curVideo"
        />
    </div>
</template>

<script>
import Video from "./video";
import DownloadWays from "./download-ways.vue";
import MobileDownloadWays from "./mobile-download-ways";
import MobileVideo from "./mobile-video";

import videos from "@/config/videos";

export default {
    components: {
        Video,
        DownloadWays,
        MobileDownloadWays,
        MobileVideo
    },

    data() {
        return {
            isMobile: this.$userAgent.isMobile,
            videos,
            curVideo: {},
            isVideoOpened: false
        };
    },

    methods: {
        onVideoClick(type, video) {
            video.type = type;
            this.isVideoOpened = true;
            this.curVideo = video;
        }
    }
};
</script>

<style lang="less" scoped>
.videos {
    &-item {
        &:nth-child(2n + 1) {
            background: #fff;
        }

        &__outer {
            width: calc(100vw / 1920 * 1200);
            margin: 0 auto;
            padding: calc(100vw / 1920 * 100);
        }

        &__title {
            width: calc(100vw / 1920 * 339);
            margin: 0 auto calc(100vw / 1920 * 30);

            img {
                width: 100%;
            }
        }

        &-list {
            display: flex;
            flex-wrap: wrap;

            &__item {
                margin-top: calc(100vw / 1920 * 50);
                margin-right: calc(100vw / 1920 * 49);
                position: relative;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    margin-top: 0;
                }

                &:nth-child(3n) {
                    margin-right: 0;
                }
            }

            &__video {
                width: calc(100vw / 1920 * 300);
                height: calc(100vw / 1920 * 170);
                position: relative;
                background-image: url("../assets/images/container/videos/video_bg.png");
                background-size: 100% 100%;
                background-position: 0 0;
                border-radius: calc(100vw / 1920 * 30);
                overflow: hidden;
                cursor: pointer;

                video {
                }
            }

            &__cover {
                width: 100%;
                height: 100%;
            }

            &__icon {
                width: calc(100vw / 1920 * 60);
                height: calc(100vw / 1920 * 60);
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                img {
                    width: 100%;
                }
            }

            &__name {
                font-size: calc(100vw / 1920 * 24);
                color: #383838;
                margin-top: calc(100vw / 1920 * 10);
                text-align: center;
            }
        }
    }
}

.downloads {
    text-align: center;

    &-title {
        color: #383838;
        font-size: calc(100vw / 1920 * 48);
        margin-top: calc(100vw / 1920 * 115);
        line-height: 1;
    }

    &-subtitle {
        color: #7e7e7e;
        font-size: calc(100vw / 1920 * 30);
        margin-top: calc(100vw / 1920 * 60);
        line-height: 1;
    }

    &-msg {
        color: #fbc73f;
        font-size: calc(100vw / 1920 * 24);
        margin: calc(100vw / 1920 * 15) 0 calc(100vw / 1920 * 20);
        line-height: 1;
    }
}

@media (max-width: 1024px) {
    .mobile-download-ways {
        background: none;
        padding-top: 0;
        height: calc(100vw / 1080 * 1100);
    }

    .downloads {
        text-align: center;

        &-title {
            color: #383838;
            font-size: calc(100vw / 1080 * 110);
            margin-top: calc(100vw / 1080 * 90);
            line-height: 1;
        }

        &-subtitle {
            color: #7e7e7e;
            font-size: calc(100vw / 1080 * 36);
            margin-top: calc(100vw / 1080 * 60);
            line-height: 1;
        }

        &-msg {
            color: #fbc73f;
            font-size: calc(100vw / 1080 * 50);
            margin: calc(100vw / 1080 * 20) 0 calc(100vw / 1080 * 60);
            line-height: 1;
        }
    }

    .videos {
        &-item {
            &:nth-child(2n + 1) {
                background: #fff;
            }

            &__outer {
                width: 100vw;
                margin: 0 auto;
                padding: calc(100vw / 1080 * 100) calc(100vw / 1080 * 70);
            }

            &__title {
                width: calc(100vw / 1080 * 630);
                margin: 0 auto calc(100vw / 1080 * 60);

                img {
                    width: 100%;
                }
            }

            &-list {
                display: flex;
                flex-wrap: wrap;

                &__item {
                    margin-top: calc(100vw / 1080 * 60);
                    margin-right: calc(100vw / 1080 * 40);
                    position: relative;

                    &:nth-child(3) {
                        margin-top: auto;
                    }

                    &:nth-child(3n) {
                        margin-right: auto;
                    }

                    &:nth-child(1),
                    &:nth-child(2) {
                        margin-top: 0;
                    }

                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }

                &__video {
                    width: calc(100vw / 1080 * 450);
                    height: calc(100vw / 1080 * 256);
                    position: relative;
                    background-image: url("../assets/images/container/videos/video_bg.png");
                    background-size: 100% 100%;
                    background-position: 0 0;
                    border-radius: calc(100vw / 1080 * 30);
                    overflow: hidden;
                    cursor: pointer;

                    video {
                        width: 100%;
                        height: 100%;
                    }
                }

                &__icon {
                    width: calc(100vw / 1080 * 90);
                    height: calc(100vw / 1080 * 91);
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);

                    img {
                        width: 100%;
                    }
                }

                &__name {
                    font-size: calc(100vw / 1080 * 30);
                    color: #383838;
                    margin-top: calc(100vw / 1080 * 15);
                    text-align: center;
                }
            }
        }
    }
}
</style>
